<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <!-- <h2 class="brand-text text-primary ml-1">
          Leasing2
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <!-- <b-img fluid :src="imgUrl" alt="Login V2" /> -->
          <div style="width:100%; height:100%; position: fixed;">
            <video style="width:100%;" poster="@/assets/images/logo/home.jpg" autoplay muted loop id="myVideo">
              <source src="@/assets/video/video.mp4" type="video/mp4" />
            </video>
          </div>
          <div class="overlay-content"></div>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->

      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Welcome to Regenwald Leasing! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <!-- form -->

          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <b-overlay :show="show" rounded="sm" opacity="0.2" no-fade>
                <!-- email -->
                <b-form-group label="Email" label-for="login-email">
                  <validation-provider #default="{ errors }" name="Email" rules="required|email">
                    <b-form-input id="login-email" v-model="userEmail" :state="errors.length > 0 ? false : null" name="login-email" placeholder="john@example.com" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Password</label>
                    <b-link :to="{ name: 'auth-forgot-password-v2' }">
                      <small></small>
                    </b-link>
                  </div>
                  <validation-provider #default="{ errors }" name="Password" rules="required">
                    <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                      <b-form-input id="login-password" v-model="password" :state="errors.length > 0 ? false : null" class="form-control-merge" :type="passwordFieldType" name="login-password" placeholder="············" />
                      <b-input-group-append is-text>
                        <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1">
                    Remember Me
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit buttons -->

                <template v-slot:overlay>
                  <div class="d-flex align-items-center">
                    <b-spinner small type="grow" variant="secondary" />
                    <b-spinner type="grow" variant="dark" />
                    <b-spinner small type="grow" variant="secondary" />
                    <!-- We add an SR only text for screen readers -->
                    <span class="sr-only">Please wait...</span>
                  </div>
                </template>

                <b-button type="submit" variant="primary" block @click="login">
                  Sign in
                </b-button>
              </b-overlay>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>

      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import { BSpinner, BOverlay, BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton } from 'bootstrap-vue';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axiosIns from '@/libs/axios';
import router from '@/router';
import useJwt from '@/auth/jwt/useJwt';

export default {
  components: {
    BSpinner,
    BOverlay,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],

  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,

      email,
      show: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },

  methods: {
    login() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.show = true;
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
            })
            .then((response) => {
              if (response.data.error == 'Unauthorized') {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Error`,
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: `Incorrect login details, please try again.`,
                  },
                });
                this.show = false;
              } else {
                const { userData } = response.data;

                useJwt.setToken(response.data.accessToken);
                useJwt.setRefreshToken(response.data.refreshToken);
                localStorage.setItem('userData', JSON.stringify(userData));
                localStorage.setItem('userPerms', JSON.stringify(response.data.userPerms));

                if (this.$socket != 'close') {
                  this.$socket.on('USERS', (users) => {
                    localStorage.setItem('chatUserList', JSON.stringify(users));
                  });
                }

                /* this.$ability.update(userData.ability);*/
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Welcome ${userData.name || userData.surname}`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                  },
                });

                this.show = false;
                router.push({ path: '/' });
              }
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: `Error occurred while logging in`,
                },
              });
              this.show = false;
              this.$refs.loginValidation.setErrors(error.response.data.error);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0);
  opacity: 50%;
  width: 100%;
  height: 100%;
}
</style>
